import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

import { currentUserVar, useReactiveVar } from "@apollo";
import { Assets } from "@assets";

import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";
import { isServerSideRendering, navigate, parseUrlParams } from "@utils";

import { DialogButton } from "@components";
import { AstronautIcon, DesertIcon, LookingIcon, RocketIcon } from "@icons";
import { Grid, Typography } from "@material";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: "auto",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 64,
  },
  textContainer: { marginLeft: "auto", marginRight: "auto" },
  errorHeaderText: {
    fontSize: 64,
    fontWeight: 500,
    fontFamily: "'Anton', sans-serif",
    textAlign: "center",
    marginTop: 20,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("xs")]: {
      fontSize: 42,
    },
  },
  thanksTextLarge: {
    fontFamily: "'Anton', sans-serif",
    fontSize: 64,
    lineHeight: 1.3,
    fontWeight: 500,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("xs")]: {
      fontSize: 42,
      lineHeight: 1.5,
    },
  },
  thanksTextMobile: {
    textAlign: "center",
  },
  redText: {
    color: "#ff3333",
  },
  confirmationText: {
    width: "100%",
    color: theme.palette.colors.text.gray,
    fontSize: 16,
    fontFamily: "Inter, sans-serif",
    marginLeft: "auto",
    marginRight: "auto",
  },
  leftAlignText: {
    textAlign: "left",
  },
  centerAlignText: {
    textAlign: "center",
  },
  buttonContainer: {
    flexDirection: "row",
    marginTop: 10,
  },
  splashImage: {
    width: "100%",
  },
  splashImageLarge: {
    width: "100%",
  },
  topPage: {
    height: 270,
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    overflow: "hidden",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  topPageFull: {
    width: "50%",
    height: "100%",
  },
  bottomPage: {
    width: "40%",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

interface ConfirmationParams {
  email: string;
  response: "verified" | "expiredCode" | "invalidCode" | "error";
}

export default function Confirmation({ location }) {
  if (isServerSideRendering()) return null;

  const classes: any = useStyles({});
  const { t } = useTranslation("signUp");

  const user = useReactiveVar(currentUserVar);

  const [params, setParams] = useState<ConfirmationParams>(null);
  const [disabled, setDisabled] = useState<boolean>(null);

  const parsedParams = parseUrlParams(location?.search as string) || undefined;

  const validRedirect =
    !user &&
    (parsedParams?.response === "verified" ||
      parsedParams?.response === "expiredCode" ||
      parsedParams?.response === "invalidCode" ||
      parsedParams?.response === "error");

  // const validRedirect = true;
  // const isMobile = false; // for testing

  // if (!isMobile) {
  // Note: not sure of the purpose of this check, but it makes mobile always return false
  // validRedirect = validRedirect && window?.parent?.history?.length === 1;
  // }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!validRedirect) {
      navigate("/", { replace: true });
    } else {
      // Parses the URL params appended by the AWS Lambda URL then removes them from the address bar
      setParams((parsedParams as unknown) as ConfirmationParams);
      // setParams({ email: "matt@foo.com", response: "verified" }); // for testing
    }
  }, []);

  const { email, response } = params || {};

  const displayButton = (): boolean => !!(response !== "error");

  const displayBackButton = () => !!(response !== "verified");

  const displayEmail = (): string => (response === "verified" ? email : "");

  const onButtonPress = (): void => {
    if (response === "expiredCode" || response === "invalidCode") {
      // if (!disabled) handleResend(email as string);
      if (!disabled) window.location.replace("mailto:support@coinmiles.io");
    } else if (response === "verified") {
      if (isMobile) {
        // Deep link to app's email sign-in screen. Meanwhile after a short pause, navigate the web page to the regular login screen. This handles the condition if the app isn't installed.
        setTimeout(function () {
          navToSignIn();
        }, 250);
        window.location.replace("coinmiles://SignInSelection?signInRedirect=true");
      } else {
        navToSignIn();
      }
    }
  };

  // const handleResend = async (email: string): Promise<void> => {
  //   setDisabled(true);
  //   try {
  //     await Auth.resendSignUp(email);

  //     const navigateToHome = (): void => navigate("/", { replace: true });

  //     ModalHelper.open({
  //       modalType: "signUpSuccess",
  //       modalProps: {
  //         emailAddress: email,
  //         navigateToHome,
  //         resendButton: false,
  //       },
  //       modalOptions: { onClose: navigateToHome },
  //     });
  //   } catch (_err) {
  //     //
  //   }
  //   setTimeout(() => setDisabled(false), 2000);
  // };

  const navToSignIn = (): void => {
    navigate("/sign-in", { state: { prefilledEmail: email } });
  };

  const navToHome = (): void => navigate("/");

  const HeroImage = {
    verified: <RocketIcon />,
    invalidCode: <LookingIcon />,
    expiredCode: <DesertIcon />,
    error: <AstronautIcon />,
  };

  return !validRedirect ? null : (
    <Grid
      container
      direction={isMobile ? "column" : "row"}
      justifyContent="flex-start"
      alignItems="center"
      alignContent="center"
      className={classes.root}
    >
      {response === "verified" ? (
        isMobile ? (
          <div className={classes.topPage}>
            <img
              src={Assets.MOBILE_DEVICE_BACKGROUND}
              alt={"coinmiles"}
              className={classes.splashImage}
            />
          </div>
        ) : (
          <div className={`${classes.topPage} ${!isMobile && classes.topPageFull}`}>
            <img
              src={Assets.MOBILE_DEVICE_BACKGROUND}
              alt={"coinmiles"}
              className={classes.splashImageLarge}
            />
          </div>
        )
      ) : (
        HeroImage[response]
      )}
      {isMobile && (
        // ------------------- MOBILE  -------------------
        <div>
          {response === "verified" ? (
            <div>
              <div
                style={{
                  width: "100%",
                  justifyContent: "flex-start",
                  paddingTop: 20,
                }}
              >
                <Grid
                  container
                  justifyContent="center"
                  alignItems="flex-start"
                  spacing={0}
                  direction="row"
                >
                  <div>
                    <Typography className={classes.thanksTextLarge}>
                      {t(`landingPage.header.verified1`)}{" "}
                      {t(`landingPage.header.verified2`)}{" "}
                    </Typography>
                  </div>
                  <div style={{ paddingLeft: 12 }}>
                    <Typography
                      className={`${classes.thanksTextLarge} ${classes.redText}`}
                    >
                      {t(`landingPage.header.verified3`)}
                    </Typography>
                  </div>
                </Grid>
              </div>

              <div style={{ marginTop: -20, justifyContent: "center" }}>
                <Typography
                  className={`${classes.thanksTextLarge} ${classes.thanksTextMobile}`}
                >
                  {t(`landingPage.header.verified4`)}
                </Typography>
              </div>
            </div>
          ) : (
            <Typography className={classes.errorHeaderText}>
              {t(`landingPage.header.${response}`)}
            </Typography>
          )}
          <div
            style={{
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                flexDirection: "row",
                justifyContent: "center",
                paddingTop: 20,
                paddingBottom: 10,
                paddingLeft: 20,
                paddingRight: 20,
                minWidth: 250,
              }}
            >
              <Typography
                className={`${classes.confirmationText} ${classes.centerAlignText}`}
              >
                {t(`landingPage.${response}`)}
              </Typography>
              <Typography
                className={`${classes.confirmationText} ${classes.centerAlignText}`}
              >
                <strong>{displayEmail()}</strong>
              </Typography>
            </div>
          </div>
        </div>
      )}
      {/*  ------------------- DESKTOP  ------------------- */}
      <div className={classes.bottomPage}>
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.textContainer}
        >
          <div
            style={{
              justifyContent: "flex-start",
              width: isMobile ? "100%" : 420,
            }}
          >
            {response === "verified" && !isMobile && (
              <div
                style={{
                  width: 420,
                  paddingTop: 15,
                  flexDirection: "row",
                  justifyContent: "flex-start",
                }}
              >
                {!isMobile && (
                  <div>
                    <Typography className={`${classes.thanksTextLarge}`}>
                      {t(`landingPage.header.verified1`)}
                    </Typography>
                    <Grid
                      container
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      alignContent="flex-start"
                      spacing={0}
                      direction="row"
                      style={{ marginTop: -20 }}
                    >
                      <div>
                        <Typography className={classes.thanksTextLarge}>
                          {t(`landingPage.header.verified2`)}{" "}
                        </Typography>
                      </div>
                      <div style={{ paddingLeft: 8 }}>
                        <Typography
                          className={`${classes.thanksTextLarge} ${classes.redText}`}
                        >
                          {t(`landingPage.header.verified3`)}
                        </Typography>
                      </div>
                    </Grid>
                    <div style={{ marginTop: -20, justifyContent: "center" }}>
                      <Typography className={`${classes.thanksTextLarge}`}>
                        {t(`landingPage.header.verified4`)}
                      </Typography>
                    </div>
                  </div>
                )}
              </div>
            )}
            {!isMobile && (
              <div
                style={{
                  justifyContent: "flex-start",
                  paddingTop: 20,
                  paddingBottom: 30,
                  paddingRight: 70,
                  minWidth: 300,
                }}
              >
                <Typography
                  className={`${classes.confirmationText} ${classes.leftAlignText}`}
                >
                  {t(`landingPage.${response}`)}
                </Typography>
                <Typography
                  className={`${classes.confirmationText} ${classes.leftAlignText}`}
                >
                  <strong>{displayEmail()}</strong>
                </Typography>
              </div>
            )}
            {/* ---------- Mobile and Desktop ----------  */}
            <Grid
              container
              justifyContent={isMobile ? "center" : "flex-start"}
              alignItems="center"
              className={classes.buttonContainer}
            >
              {displayButton() && response === "verified" && (
                <Grid style={{ paddingBottom: 7 }}>
                  <DialogButton
                    width={223}
                    height={64}
                    colorVariant="pink"
                    onClick={onButtonPress}
                    style={{ paddingRight: 10, borderRadius: 60 }}
                  >
                    {isMobile
                      ? t(`landingPage.button.openWithApp`)
                      : t(`landingPage.button.openWithWebsite`)}
                  </DialogButton>
                </Grid>
              )}
              {displayButton() &&
                (response === "expiredCode" || response === "invalidCode") && (
                  <div style={{ padding: 5 }}>
                    {/* <a href="mailto:support@coinmiles.io"> */}
                    <DialogButton
                      width={223}
                      height={64}
                      colorVariant="pink"
                      onClick={onButtonPress}
                      style={{ paddingRight: 10, borderRadius: 60 }}
                    >
                      {t(`landingPage.button.${response}`)}
                    </DialogButton>
                    {/* </a> */}
                  </div>
                )}
              {displayBackButton() && (
                <div style={{ padding: 5 }}>
                  <DialogButton
                    width={223}
                    height={64}
                    colorVariant={response === "error" ? "pink" : "white"}
                    onClick={navToHome}
                    style={{ paddingRight: 10, borderRadius: 60 }}
                  >
                    {t(`landingPage.button.backToHome`)}
                  </DialogButton>
                </div>
              )}
            </Grid>
          </div>
        </Grid>
      </div>
    </Grid>
  );
}
